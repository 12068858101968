//
// Image slider
//

import Swiper from 'swiper';

const imageSlider = function() {
  const addA11y = (slider) => {
    const currentIndex = slider.activeIndex;
    const allSlides = slider.slides;
    const allSlidesArray = Object.values(slider.slides);
    const selectedSlide = allSlides[currentIndex];

    allSlidesArray.forEach((slide)=> {
      if (slide.nodeType == 1) {
        slide.setAttribute('aria-hidden', 'true');
      }
    });
    selectedSlide.setAttribute('aria-hidden', 'false');
  }
  const swiper = new Swiper('.image-slider', {
    pagination: {
      el: '.swiper-pagination',
      grabCursor: true,
      clickable: true
    },
    a11y: true,
    on: {
      init: function() {
        addA11y(this);
      },
      transitionEnd: function () {
        addA11y(this);
      }
    }
  });
};

imageSlider();
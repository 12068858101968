// Information needed for connecting to our CraftQL endpoint
export const apiToken = 'd3kb3S-uFip6v9RIp9Q9vKsa3QzWNHSG0guyDP8eRtBY0KD9Ut9jaRpN0KQZhljk';
export const apiUrl = '/api';

// Configure the api endpoint
export function configureApi(url, token) {
  return {
    baseURL: url,
  };
};

// Execute a GraphQL query by sending an XHR to our api endpoint
export function executeQuery(api, query, variables, callback) {
  api.post('', {
    query: query,
    variables: variables
  }).then((result) => {
    if (callback) {
      callback(result.data);
    }
  }).catch((error) => {
  })
};

let query = window.location.search.substring(1);
export function getQueryParam(param) {
  if (query.length === 0) {
    return false;
  }
  let params = decodeURIComponent(query).split('&');
  let results = [];
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      results.push(decodeURIComponent(pair[1]))
    }
  }
  if (results.length === 0) {results = '';}
  if (results.length === 1 && param !== 'cat' && param !== 'cat-id') {results = results[0];}

  return results;
}

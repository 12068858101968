//
// Copy to clipboard
//

import * as Clipboard from 'clipboard';

var clip = new Clipboard('.clipboard');

clip.on('success', function(e) {
  const copyButton = e.trigger;
  const copyButtonTextContainer = copyButton.querySelector('.copy-button-text');
  const buttonText = copyButtonTextContainer.innerHTML;
  const toggleClasses = ['text-coral', 'border-b-2', 'border-coral'];

  copyButtonTextContainer.classList.add(...toggleClasses);
    copyButton.classList.add('pointer-events-none');
    copyButton.classList.remove('group');
  copyButtonTextContainer.innerHTML = 'Link is copied';
  setTimeout(function(){ 
    copyButtonTextContainer.classList.remove(...toggleClasses)
    copyButton.classList.add('group');
    copyButton.classList.remove('pointer-events-none');
    copyButtonTextContainer.innerHTML = buttonText;
  }, 4000);

  e.clearSelection();
});

clip.on('error', function(e) {
  console.error('Action:', e.action);
  console.error('Trigger:', e.trigger);
});
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "flex", class: { "pb-9": !_vm.last } }, [
    _c("div", { staticClass: "pr-4-5 md:pr-11 flex-shrink-0 pl-2" }, [
      _c(
        "h3",
        { staticClass: "font-form-a1 text-6xl md:text-5xxl mb-0 leading-none" },
        [_vm._v(_vm._s(_vm.moment(_vm.date.date).format("DD/MM")))]
      ),
      _vm._v(" "),
      _c(
        "time",
        {
          staticClass: "calendar-text",
          attrs: {
            datetime: _vm.moment(_vm.date.date).format("DD.MM.YYYY ddd"),
          },
        },
        [_vm._v(_vm._s(_vm.moment(_vm.date.date).format("ddd")))]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "py-4 w-full flex flex-wrap items-start" },
      _vm._l(_vm.date.events, function (event, i) {
        return _c(
          "div",
          { key: event.id, staticClass: "w-full" },
          [
            _vm.showTimes(event).length > 0
              ? _c("calendar-item", {
                  attrs: {
                    event: event,
                    date: _vm.date,
                    "first-item": i === 0,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="flex" :class="{ 'pb-9': !last }">
    <div class="pr-4-5 md:pr-11 flex-shrink-0 pl-2">
      <h3 class="font-form-a1 text-6xl md:text-5xxl mb-0 leading-none">{{moment(date.date).format('DD/MM')}}</h3>
      <time class="calendar-text" :datetime="moment(date.date).format('DD.MM.YYYY ddd')">{{moment(date.date).format('ddd')}}</time>
    </div>
    <div class="py-4 w-full flex flex-wrap items-start">
      <div class="w-full" v-for="(event, i) in date.events" :key="event.id">
        <calendar-item v-if="showTimes(event).length > 0" :event="event" :date="date" :first-item="i === 0" />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import CalendarItem from './CalendarItem';

export default {
  name: 'CalendarDate',
  props: ['date', 'first', 'last'],
  components: { CalendarItem },
  methods: {
    showTimes: function (event) {
      let showTimes = !!event.showTime ? [moment(event.showTime).format('kk:mm')] : [];
      let irregularShowTimes = [];
      if (event.irregularShowTimes) {
        irregularShowTimes = event.irregularShowTimes.filter(element => {
          return moment(element.date).format('YYYY-MM-DD') === this.date.date;
        })
      }
      if (irregularShowTimes.length > 0) {
        showTimes = irregularShowTimes.map(element => {
          return moment(element.time).format('kk:mm');
        })
      }
      return irregularShowTimes;
    }
  },
};
</script>

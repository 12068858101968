<template>
  <VueSlickCarousel v-bind="settings" @afterChange="afterCurrentChange">
    <slot />
  </VueSlickCarousel>
</template>

<script>
import Vue from 'vue';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Carousel',
  components: { VueSlickCarousel },
  props: ['settings'],
  methods: {
    afterCurrentChange: function(slideIndex) {
      this.$emit('slide-index', slideIndex)
    },
  },
};
</script>

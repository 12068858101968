// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes';
import 'picturefill'
import Swiper from 'swiper';

// Our own helpers
import './helpers/objectfitFallback';
import reframe from './helpers/iframe';

// Our own components
import './components/experiencesCarousel';
import './components/imageSlider';
import './components/experiences';
import './components/primaryNavigation';
import './components/toggleContent';
import './components/copyText';
import './components/floorPlan';
import './components/gallery';
import './components/cookieBanner';
import './components/animatedText';
import './components/performancesLiftups';
import './components/experienceNavigation';

// Window onload (after all dom and images are loaded completely)
window.onload = function () {
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')); // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading');

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table');
  if (tables.length) {
    if (!('length' in tables)) tables = [tables];
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i];

      const div = document.createElement('div');
      div.className = 'table-container';

      table.parentNode.insertBefore(div, table);
      table.parentNode.removeChild(table);
      div.appendChild(table);
    };
  };

  // Back to last page functionality
  let backButton = document.querySelector(".back-button");
  if (!!backButton) {
    backButton.addEventListener("click", function (e) {
      e.preventDefault();
      window.history.back();
    });
  }


  const inputLabels = document.querySelectorAll('input[type="radio"] + label, input[type="checkbox"] + label');

  const preventScroll = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      window.removeEventListener('keydown', preventScroll);
    }
  }

  const selectInput = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      window.addEventListener('keydown', preventScroll, false);
      const label = event.target.closest('label');
      const forValue = label.getAttribute('for');
      const input = document.getElementById(forValue);

      input.click();
      return false;
    }
    if (event.keyCode === 13) {
      event.preventDefault();

      window.event.keycode = 9;
    }
  }

  inputLabels.forEach(inputLabel => {
    inputLabel.addEventListener('keydown', selectInput, false);
  })

  let closeDropdown = function (event) {
    if ((!!event.target && event.target.classList.contains('filterDropdownToggle')) || (!!event.target.parendtNode && event.target.parentNode.classList.contains('filterDropdownToggle'))) {
      return true;
    }

    let dropdownItems = document.getElementsByClassName('filterDropdownItem');

    for (let i = dropdownItems.length - 1; i >= 0; i--) {
      dropdownItems[i].classList.add('hidden');
    }
  }

  document.addEventListener('click', closeDropdown);

  let toggleItems = document.getElementsByClassName("filterDropdownToggle");
  for (let i = toggleItems.length - 1; i >= 0; i--) {
    toggleItems[i].addEventListener("click", toggleDropdown);
  }

  function toggleDropdown(event) {
    let dropdownItems = document.getElementsByClassName('filterDropdownItem');

    for (let i = dropdownItems.length - 1; i >= 0; i--) {
      if (
        !(dropdownItems[i].previousElementSibling === event.target ||
          dropdownItems[i].previousElementSibling === event.target.parentNode)
      ) {
        dropdownItems[i].classList.add('hidden');
      }
    }

    if (this.nextElementSibling.classList.contains('hidden')) {
      this.nextElementSibling.classList.remove('hidden');
    } else {
      this.nextElementSibling.classList.add('hidden');
    }
  }
};

import Vue from 'vue';
import Carousel from '../../vue/Carousel';

if (document.querySelectorAll('[data-carousel]').length > 0) {
  document.querySelectorAll('[data-carousel]').forEach(element => {
    new Vue({
      el: element,
      name: 'carousel',
      delimiters: ['<%', '%>'],
      components: { Carousel },
      data () {
        return {
          currentTitle: '',
          currentLinkTo: '',
          currentIndex: undefined
        }
      },
      created: function() {
        this.currentIndex = 0;
      },
      watch: {
        currentIndex: function(val) {
          this.setCurrentValues(val);
        }
      },
      methods: {
        getcurrentIndex (index) {
          this.currentIndex = index;
        },
        setCurrentValues (val) {
          const currentItem = this.$el.querySelector(`[data-index="${val}"]`).querySelector('[data-item]');
          const currentTitle = currentItem.dataset.title;
          const currentLinkTo = currentItem.dataset.linkto;
          this.currentTitle = currentTitle;
          this.currentLinkTo = currentLinkTo;
        }
      }
    })
  });
}
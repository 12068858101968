var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "block",
      class: { "pt-12": !_vm.firstItem },
      attrs: { href: _vm.event.url },
    },
    [
      _c(
        "section",
        { staticClass: "relative flex flex-row max-w-sm md:max-w-none" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap md:justify-between md:flex-no-wrap gap-8 xl:gap-12 w-full",
            },
            [
              _c("div", { staticClass: "md:w-48 lg:w-2/4" }, [
                _c(
                  "h2",
                  {
                    staticClass:
                      "font-form-a1 text-2xl md:4xl lg:text-4xxl mb-4",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.event.title.length > 63
                            ? _vm.event.title.slice(0, 60) + "..."
                            : _vm.event.title
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.event.primaryExperienceType.length > 0
                  ? _c(
                      "div",
                      { staticClass: "pr-6-5 md:pr-10-5 flex flex-wrap" },
                      _vm._l(
                        _vm.event.primaryExperienceType,
                        function (type, i) {
                          return _c(
                            "span",
                            {
                              key: i,
                              staticClass:
                                "font-form-a1 text-lg text-gray-500 md:small-title",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    i <
                                      _vm.event.primaryExperienceType.length - 1
                                      ? type.title + ", "
                                      : type.title
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-col justify-between max-w-140px lg:w-1/4",
                },
                [
                  !!_vm.event.operator && _vm.event.operator[0]
                    ? _c(
                        "h3",
                        {
                          staticClass:
                            "font-form-a1 text-black text-lg md:small-title mb-4",
                        },
                        [_vm._v(_vm._s(_vm.event.operator[0].title))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showTimes.length > 0
                    ? _c(
                        "div",
                        { staticClass: "pr-6-5 md:pr-10-5" },
                        [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "font-form-a1 text-silverChalice text-lg md:small-title",
                            },
                            [_vm._v(_vm._s(_vm.vueTranslations.Time))]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.showTimes, function (showTime, i) {
                            return _c("div", { key: i }, [
                              showTime
                                ? _c(
                                    "time",
                                    {
                                      staticClass:
                                        "font-form-a1 text-lg md:small-title",
                                      attrs: { datetime: showTime },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(showTime) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "lg:text-right lg:w-1/4" }, [
                _c("div", { staticClass: "inline-flex md:flex-col" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "button button button--white--border w-full mr-2-5 md:mr-0 md:mb-2-5",
                      attrs: { href: _vm.event.url },
                    },
                    [_vm._v(_vm._s(_vm.vueTranslations.readMore))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "button inline-flex items-center self-start justify-center button-black text-white text-2xl font-form-a1 lg:mt-auto whitespace-no-wrap",
                      class: {
                        "h-0 opacity-0 pointer-events-none py-0":
                          !_vm.event.ticketLink,
                      },
                      attrs: {
                        href: _vm.event.ticketLink,
                        tabindex: !_vm.event.ticketLink ? -1 : 1,
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(
                          _vm._s(_vm.vueTranslations.buyTicketFromProvider)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "hidden xs:block" }, [
                        _vm._v(_vm._s(_vm.vueTranslations.buyTickets)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "xs:ml-2 mt-1" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "fill-current",
                            attrs: {
                              width: "13px",
                              height: "18px",
                              viewBox: "0 0 13 18",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      fill: "#FFFFFF",
                                      "fill-rule": "nonzero",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M12,0 L12,16 L8.51876872,16 L8.37704804,15.3005854 C8.14016877,14.1315761 7.14720045,13.2913969 6,13.2913969 C4.98495042,13.2913969 4.08063462,13.951606 3.72971592,14.9194361 L3.68711866,15.0473904 L3.6529429,15.1690818 L3.62295247,15.3005829 L3.48123128,16 L0,16 L0,0 L12,0 Z M10.3026667,1.76069565 L1.69666667,1.76069565 L1.69666667,7.85878261 L3.27676764,7.85938945 L3.27676764,9.62072155 L1.69666667,9.62017391 L1.69666667,14.2386087 L2.16466667,14.237913 L2.22785657,14.0830065 C2.78515934,12.7760039 3.94049868,11.8352166 5.30906088,11.5910356 L5.47428719,11.5650988 L5.6476568,11.5457456 L5.82847108,11.5338547 L6,11.5300648 C7.63157632,11.5300648 9.10204521,12.531532 9.76693504,14.0723326 L9.83466667,14.237913 L10.3026667,14.2386087 L10.3026667,9.62017391 L8.58585859,9.62072155 L8.58585859,7.85938945 L10.3026667,7.85878261 L10.3026667,1.76069565 Z M7.39797977,7.85938945 L7.39797977,9.62072155 L4.46464646,9.62072155 L4.46464646,7.85938945 L7.39797977,7.85938945 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
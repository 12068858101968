const performancesLiftups = document.querySelector('[data-performances-liftups]');
if(!!performancesLiftups) {
  const performancesLiftupButtons = performancesLiftups.querySelectorAll('[data-performances-liftup]');

  const preventScroll = (event) => {
    if(event.keyCode === 32) {
      event.preventDefault();
      window.removeEventListener('keydown', preventScroll);
    }
  }

  const displayEvents = (event) => {
    const section = event.target.dataset.performancesLiftup;

    performancesLiftupButtons.forEach((button) => {
      button.classList.remove('text-black');
    });

    event.target.classList.add('text-black');

    const allSections =  performancesLiftups.querySelectorAll('[data-event-entries]');
    allSections.forEach((each)=>{
      each.classList.add('hidden');
    })
    const selectedSection = performancesLiftups.querySelector(`#${section}`);
    selectedSection.classList.remove('hidden');
  }

  if(!!performancesLiftups) {
    performancesLiftupButtons.forEach((button) => {
      button.addEventListener('click', displayEvents);
      button.addEventListener('keydown', (event) => {
        if(event.keyCode === 32 || event.keyCode === 13) {
          event.preventDefault();
          if(event.keyCode === 32) {
            window.addEventListener('keydown', preventScroll, false);
          }
          displayEvents(event);
    
          return false
        }
      });
    })
  }
}
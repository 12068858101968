var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vCalendarIsDefined
    ? _c(
        "div",
        {
          staticClass: "relative xl:mr-3 flex",
          class: { "mt-4": _vm.isMobile, "mt-3": !_vm.isMobile },
        },
        [
          _c(
            "form",
            {
              staticClass: "w-full xl:w-auto",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "heading-6 mb-6 text-black leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto",
                },
                [_vm._v(_vm._s(_vm.vueTranslations.chooseDate))]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex h-auto relative bg-blue-dark" },
                [
                  _c("v-date-picker", {
                    attrs: {
                      attributes: _vm.attrs,
                      "is-range": "",
                      locale: _vm.siteLang,
                      color: "gray",
                      "min-date": new Date(),
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "relative" }, [
                    _vm.date !== null
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "text-black font-form-a1 uppercase absolute z-40",
                            class: {
                              "h-14-5 px-6": _vm.isMobile,
                              "h-9-5 px-4": !_vm.isMobile,
                            },
                            staticStyle: { bottom: "0", right: "0" },
                            attrs: { type: "button" },
                            on: { click: _vm.clearDates },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.vueTranslations.clear) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
const allFloorPlanElements = document.querySelectorAll('[data-floor-plan]');

const preventScroll = (event) => {
  if(event.keyCode === 32) {
    event.preventDefault();
    window.removeEventListener('keydown', preventScroll);
  }
}

const switchFloorPlan = (event, floorPlan, allToggles, allContent) => {
  const elem = event.target.closest('[data-floor-plan-toggle]');
  const toggleValue = elem.dataset.floorPlanToggle;
  const content = floorPlan.querySelector(`[data-floor-plan-content=${toggleValue}]`);

  allToggles.forEach((el)=> {
    el.classList.remove('selected')
  });

  allContent.forEach((el)=> {
    el.classList.remove('show-content')
  });

  if( content.dataset.floorPlanContent === toggleValue) {
    elem.classList.add('selected');
    content.classList.add('show-content');
  }
}

allFloorPlanElements.forEach((floorPlan) => {
  const allToggles = floorPlan.querySelectorAll('[data-floor-plan-toggle]');
  const allContent = floorPlan.querySelectorAll('[data-floor-plan-content]');
  allToggles[0].classList.add('selected');
  allContent[0].classList.add('show-content');
  floorPlan.addEventListener('click', (event) => {
    if(event.target.closest('[data-floor-plan-toggle]')) {
      switchFloorPlan(event, floorPlan, allToggles, allContent);
    }
  });
  floorPlan.addEventListener('keydown', (event) => {
    if(event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      if(event.keyCode === 32) {
        window.addEventListener('keydown', preventScroll, false);
      }
      if(event.target.closest('[data-floor-plan-toggle]')) {
        switchFloorPlan(event, floorPlan, allToggles, allContent);
      }

      return false
    } else if (event.keyCode == 39) {     
      const nextSibiling = event.target.nextElementSibling; 
      !!nextSibiling && nextSibiling.focus();

    } else if (event.keyCode == 37) {      
      const prevSibiling = event.target.previousElementSibling; 
      prevSibiling && prevSibiling.focus();
    }
  });
});

import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone';
import {configureApi, apiToken, apiUrl, getQueryParam, executeQuery} from './searchCommon';

// import CategorySearchMeta from '../../vue/CategorySearchMeta.vue'
import CategorySearchList from '../../vue/calendar/CategorySearchList.vue'
import CalendarPicker from '../../vue/calendar/CalendarPicker.vue'
// import Pagination from '../../vue/Pagination.vue'
import CategoryFilter from '../../vue/experiences/CategoryFilter.vue';
import ViewSelector from '../../vue/experiences/ViewSelector.vue';

const siteLang = document.documentElement.lang;

moment.tz.setDefault("Europe/Helsinki");
moment.locale(siteLang);
moment.weekdays(true);

Vue.prototype.moment = moment;

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, apiToken)),
    searchQuery: '',
    searchCategory: {},
    searchCategories: [],
    searchResults: [],
    searchSorting: 'startDate asc',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: null,
    isLoading: true,
    isReady: false,
    viewMode: 'list',
    listResults: [],
    siteLang,
    savedEndDate: null,
    categoryIds: [],
    allDates: []
  };
}

let filterChangeTimer, excludedIdsTimer;

let searchApp = function() {

// What to search for
const searchSections = "experiences";
const searchEntries = ["experience_Entry"];
const searchTransform = [];

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries +
      `
      ... on ${entryType} {
        id
        title
        url
        startDate
        endDate
        operator {
          title
        }
        showTime
        irregularShowTimes {
          date
          time
        }
        ticketLink
        primaryExperienceType {
          title
        }
        cardImage @transform(transform: "thumb") {
          url
        }
        image @transform(transform: "thumb") {
          url
        }
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($site: [String], $primaryExperienceType: [QueryArgument] $section: [String],   $operator: [QueryArgument], $venueCategory: [QueryArgument], $startDate: [QueryArgument], $endDate: [QueryArgument], $needle: String, $orderBy: String)
    {
      entries( site: $site primaryExperienceType: $primaryExperienceType orderBy: $orderBy search: $needle section: $section  operator: $operator venueCategory: $venueCategory startDate: $startDate endDate: $endDate) {
        ${searchQueries}
      }
    }
  `;

  new Vue({
    el: document.getElementById('experiencesCalendar'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategoryFilter,
      ViewSelector,
      CalendarPicker
    },
    beforeCreate: function() {
    },
    created: function() {
      this.getCategories();
      let categoryIds = [];
      const searchParam = getQueryParam('q');
      const categoryParam = getQueryParam('cat');
      const categoryIdParam = getQueryParam('cat-id');
      const pageParam = getQueryParam('page');
      const startParam = getQueryParam('start');
      const endParam = getQueryParam('end');
      const viewModeParam = getQueryParam('view');

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryParam) {
        this.searchCategory.slug = categoryParam;
      }
      if(!!categoryIdParam) {
        this.categoryIds = categoryIdParam.map(id => parseInt(id));
        // this.searchCategories = categoryIds;
      }
      if(!!startParam) {
        this.startDate = startParam;
      }

      if(!!endParam) {
        this.endDate = endParam;
      }

      if (!!viewModeParam && (viewModeParam === 'list' || viewModeParam === 'calendar')) {
        this.viewMode = viewModeParam;
      }
      this.sectionType = searchSections;
    },
    mounted: function() {
      this.performSearch();
    },
    updated: function() {
    },
    destroyed: function destroyed() {
    },
    watch: {
      searchQuery: function(val, oldVal) {
        this.performSearch();
      },
      searchCategories: {
        handler: function(val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      startDate: {
        handler: function(val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      endDate: {
        handler: function(val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function() {
        this.performSearch();
      },
      viewMode: function(val, oldVal) {
        if ('URLSearchParams' in window) {
          var searchParams = new URLSearchParams(window.location.search)
          searchParams.set('view', val);
          var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
          history.pushState(null, '', newRelativePathQuery);
        }
        if(val === 'list') {
          this.endDate = '';
          if (this.listResults.length > 0) {
            this.searchResults = this.listResults;
          } else {
            this.performSearch();
          }
        } else if (val === 'calendar') {
          if (this.savedEndDate) {
            // this.endDate = this.savedEndDate;
          } else {
            // this.endDate = moment().format('YYYY-MM-DD');
          }
          this.performSearch();
        }
      },
    },
    filters: {
    },
    computed: {
      eventsByDate: function() {
        let self = this;
        const events = self.searchResults;
        const startDate = moment(self.startDate);
        let endDate = null;
        if (self.endDate) {
          endDate = moment(self.endDate);
        } else {
          if (events.length > 0) {
            const lastEvent = events.reduce((a, b) => {
              return new Date(a.endDate) > new Date(b.endDate) ? a : b;
            });
            endDate = moment(lastEvent.endDate);
          }
        }
        const currentDate = startDate.subtract(1, 'days');

        let eventsByDate = {};
          if (events && events.length > 0) {
          while (currentDate.add(1, 'days').diff(endDate) <= 0) {
            const formattedDate = currentDate.format('YYYY-MM-DD');

            let eventsInDate = [];
            events.forEach((event) => {
              // format dates to make them the right time zone
              const formattedStartDate = moment(event.startDate).format();
              const formattedEndDate = moment(event.endDate).format();
              const eventStartDate = moment(formattedStartDate).unix();
              const eventEndDate = moment(formattedEndDate).unix();
              if (eventStartDate <= currentDate.unix() && currentDate.unix() <= eventEndDate) {
                eventsInDate = eventsInDate.concat([event]);
              }
            })

            if (eventsInDate.length > 0) {
              const sortedEvents = _.orderBy(eventsInDate, (evt) => {
                return moment(evt.showTime);
              }, ['asc']);
              eventsByDate[formattedDate] = {};
              eventsByDate[formattedDate].date = formattedDate;
              eventsByDate[formattedDate].events = sortedEvents;
            }
          }
        }
        return eventsByDate;
      }
    },
    methods: {
      performSearch() {
        let self = this;

        self.isLoading = true;
        self.setHistory();

        let searchTitle = !!self.searchQuery ? `title:'${self.searchQuery}'` : '';

        let primaryExperienceTypes = [];
        let operators = [];
        let venues = [];
        let searchCategoriesString = '';
        if (!_.isEmpty(self.searchCategories)) {
          self.searchCategories.forEach(category => {
            if (category.parentCategory.handle === 'experienceType') {
              if (primaryExperienceTypes.length < 1) {
                primaryExperienceTypes.push('and');
              }
              primaryExperienceTypes.push(category.id);
            }
            if (category.parentCategory.handle === 'operator') {
              operators.push(category.id);
            }
            if (category.parentCategory.handle === 'venue') {
              venues.push(category.id);
            }
          });
        } else {
          searchCategoriesString = '';
        }
        const startDateString = !_.isEmpty(_.get(self, 'endDate')) ? `<= ${self.endDate}` : '';
        const endDateString = !_.isEmpty(_.get(self, 'startDate')) ? `>=${self.startDate}` : '';

        // Set the variables we will pass in to our query
        let variables = {
            sections: searchSections,
            needle: _.compact([searchTitle, searchCategoriesString]).join(' '),
            orderBy: self.searchSorting,
            site: siteLang
        };

        if (!!startDateString) {
          variables.startDate = startDateString;
          // self.savedEndDate = self.endDate;
        }

        if (!!endDateString) {
          variables.endDate = endDateString;
        }

        if (primaryExperienceTypes.length > 0) {
          variables.primaryExperienceType = primaryExperienceTypes;
        }
        if (operators.length > 0) {
          variables.operator = operators;
        }
        if (venues.length > 0) {
          variables.venueCategory = venues;
        }

        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            const dataPath = data.data;
            self.searchResults = dataPath.entries;
            if (self.viewMode === 'list') {
              self.listResults = dataPath.entries;
            }

            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function() {
        let self = this;
        let paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategories[0], 'slug')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? ('&cat=' + o.slug) : (paramString += '?cat=' + o.slug);
            paramString += !!paramString ? ('&cat-id=' + o.id) : (paramString += '?cat-id=' + o.id);
          })
        }
        if(!_.isEmpty(self.startDate)) {
          paramString += !!paramString ? ('&start=' + moment(self.startDate).format('YYYY-MM-DD')) : (paramString += '?start=' + moment(self.startDate).format('YYYY-MM-DD'));
        }
        if(!_.isEmpty(self.endDate)) {
          paramString += !!paramString ? ('&end=' + moment(self.endDate).format('YYYY-MM-DD')) : (paramString += '?end=' + moment(self.endDate).format('YYYY-MM-DD'));
        }
        if(!_.isEmpty(self.viewMode)) {
          paramString += !!paramString ? ('&view=' + self.viewMode) : (paramString += '?view=' + self.viewMode);
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        let top = 0;

        setTimeout(function() {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      getCategories: function() {
        let self = this;
        const categoryQuery =
        `
        query searchQuery
          {
            entries(section: "experiences") {
              ... on experience_Entry {
                irregularShowTimes {
                  date
                  time
                }
              }
            }
          }
        `;
        executeQuery(self.searchApi, categoryQuery, [], (data) => {
          const dataPath = data.data;
          let dates = [];
          _.each(dataPath.entries, (event) => {
            _.each(event.irregularShowTimes, (showTime) => {
              dates.push(showTime.date);
            })
          })
          dates = _.uniq(dates);
          const dateDates = _.map(dates, date => { return new Date(date)} )
          self.allDates = dateDates;
        });
      }
    },
  });
};

!!document.getElementById('experiencesCalendar') && searchApp();

import Vue from 'vue'
// mobile: 720px
if (!!document.getElementById("primary-navigation")) {

  const offsetValue = 60

  const primaryNavigation = new Vue({
    name: 'primary-navigation',
    el: '#primary-navigation',
    data: {
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
      isAtTop: true,
      open: false,
      searchActive: false,
      isMobile: false
    },

    created() {
      this.onResize();
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)
      window.addEventListener('keydown', this.closeNavOnEscape)
    },

    mounted() {
      this.addAnchorLinkListeners();
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll)
      window.removeEventListener('resize', this.onResize)
      window.removeEventListener('keydown', this.closeNavOnEscape)
    },

    methods: {
      onScroll() {
        if (!this.open) {
          this.isAtTop = window.pageYOffset === 0 ? true : false
        } else {
          this.isAtTop = false
        }

        if (window.pageYOffset < 0 || Math.abs(window.pageYOffset - this.lastScrollPosition) < offsetValue) {
          return
        }

        this.showNavbar = window.pageYOffset < this.lastScrollPosition
        this.lastScrollPosition = window.pageYOffset
      },
      onResize() {
        window.innerWidth < 720;
        this.isMobile = true;
      },
      toggleNavigation() {
        this.open = !this.open

        if (this.open) {
          const nav = this.$el.getElementsByClassName('site-header__desktop-nav');
          if (nav.length > 0) {
            setTimeout(() => {
              const firstItem = nav[0].querySelector('nav ul li:first-of-type h5');
              if (firstItem) {
                firstItem.focus();
              }
            }, 1000);
          }
        }

        if (!this.open) {
          setTimeout(() => {
            const toggleButton = this.$el.querySelector('.site-header__toggle-navigation');
            if (toggleButton) {
              toggleButton.focus();
            }
          }, 1000);
        }

        // Disable content scroll when navigation is open
        document.body.classList.toggle('overflow-hidden');
      },
      toggleSearch() {
        this.searchActive = !this.searchActive
      },
      closeNavOnEscape(event) {
        if (this.open && event.keyCode === 27) {
          this.open = false;
          document.body.classList.remove('overflow-hidden');

          setTimeout(() => {
            const toggleButton = this.$el.querySelector('.site-header__toggle-navigation');
            if (toggleButton) {
              toggleButton.focus();
            }
          }, 1000);

          // window.removeEventListener('keydown', this.closeNavOnEscape)
        }
      },
      addAnchorLinkListeners() {
        const navLinks = this.$el.querySelectorAll('#primary-navigation ul li a[href*="#"]');
        navLinks.forEach(link => {
          link.addEventListener('click', this.handleAnchorClick.bind(this));
        });
      },
      handleAnchorClick(event) {
        const link = event.currentTarget.getAttribute('href');
        if (this.hasAnchor(link)) {
          this.open = false;
          document.body.classList.remove('overflow-hidden');

          setTimeout(() => {
            const toggleButton = this.$el.querySelector('.site-header__toggle-navigation');
            if (toggleButton) {
              toggleButton.focus();
            }
          }, 1000);
        }
      },
      hasAnchor(link) {
        try {
          const url = new URL(link, window.location.origin);
          return url.hash.length > 0;
        } catch (e) {
          // If the input is not a valid URL, return false
          return false;
        }
      }
    }
  })
}

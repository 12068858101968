// Selectors
const selectors = {
  elementsClass: "animated-text",
  itemClass: "animated-text-item",
  randomSelectedClass: "animated-text-item--selected",
  animationStartClass: 'animated-text--start'
};

const animations = [
  "flex-single"
];
// Get all the elements from the dom
const elements = document.querySelectorAll(`.${selectors.elementsClass}`);

// Loop through all the elements
elements.forEach((element) => {
  // Set the animation class
  let animation = animations[Math.floor(Math.random() * animations.length)];

  if (element.hasAttribute("data-animation")) {
    animation = element.getAttribute("data-animation");
  }

  // Get the text of the element, the sentence
  const sentence = element.innerText || element.textContent;
  // Get all the characters from the word

  let modifiedSentence = () => {
    const characterSets = sentence.split(' ');
    let modifiedChracterSets = [];
    const indexes = [0, 1, 2];
    const randomGroupIndex = indexes[Math.floor(Math.random() * indexes.length)];
    characterSets.forEach((characterSet, index) => {
      const characters = characterSet.split("");
      // Decide on the character that needs to be animated
      let randomIndex = -1;
      if (randomGroupIndex === index) {
        randomIndex = Math.floor(Math.random() * characters.length);

        if (randomIndex == 0 && randomIndex !== characters.length || characters[randomIndex] == " ") {
          randomIndex = randomIndex + 1;
        } else {
          randomIndex = randomIndex - 1;
        }
      }

      const modifiedCharacterSet = characters.map((character, index) => {
        let output = character;
        let styles = '';

        if (element.hasAttribute("data-iteration")) {
          styles = `animation-iteration-count: ${element.getAttribute("data-iteration")};`;
        }
        // check that we are not on a empty character
        if (character !== " ") {
          const showSelectedClass = (animation == 'flex-single') ? index == randomIndex : (index == randomIndex || index < randomIndex + 3 && index > randomIndex - 3);
          output = `<span class="${selectors.itemClass} ${(showSelectedClass) ? selectors.randomSelectedClass : ''}" data-character="${character}" data-position="${index}" style="--char-index:${index};${styles}">${character}</span>`;
        }
        return output;
      });

      const output = `<span class="hyphens-auto">${modifiedCharacterSet.join('')}</span>`;
      modifiedChracterSets = modifiedChracterSets.concat([output]);
    });
    return modifiedChracterSets.join('&shy;<span style="width: 20px;"> </span>');
  }

  element.innerHTML = modifiedSentence();
  if (!element.hasAttribute("data-animation")) {
    element.setAttribute("data-animation", animation);
  }

  window.addEventListener('load', (event) => {
    setTimeout(() => {
      element.classList.add(selectors.animationStartClass);
    }, 2000 * Math.random());
  });
});
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading || !_vm.isReady,
            expression: "isLoading || !isReady",
          },
        ],
        staticClass:
          "cardLoader px-2 xl:px-3 pb-4 xl:pb-6 w-full md:w-1/2 lg:w-1/3",
        attrs: { "leave-stagger": "500" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col h-full p-5 pt-8 pb-4 border-grey-light border-solid border",
          },
          [
            _c("div", { staticClass: "line-medium flex items-center" }),
            _vm._v(" "),
            _c("h3", { staticClass: "line-large mt-4" }),
            _vm._v(" "),
            _c("h3", { staticClass: "line-medium mt-4" }),
            _vm._v(" "),
            _c("h3", { staticClass: "line-medium-large mt-4" }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
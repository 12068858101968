var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-full relative flex flex-wrap py-4",
      style: { minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "absolute w-full pin-x flex justify-center content-start",
          staticStyle: { "z-index": "-1" },
        },
        [
          _vm.isLoading
            ? _c(
                "span",
                { staticClass: "text-2xl font-form-a1 uppercase mt-10" },
                [_vm._v(_vm._s(_vm.vueTranslations.loading))]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.items.length > 0 && _vm.viewMode === "list" && !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "flex flex-wrap",
              staticStyle: { "min-width": "100%" },
              attrs: { "item-selector": ".item" },
            },
            _vm._l(_vm.items, function (item, key, index) {
              return _c(
                "div",
                {
                  key: key,
                  staticClass:
                    "card w-full md:w-1/2 lg:w-1/4 flex-shrink-0 px-2 max-w-card mb-12 md:mb-16",
                },
                [
                  _c("div", { staticClass: "flex flex-col h-full" }, [
                    _c("div", { staticClass: "block relative w-full" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: item.url,
                            title: item.title,
                            tabindex: "-1",
                          },
                        },
                        [
                          _c(
                            "figure",
                            {
                              staticClass:
                                "w-full pb-aspect130 relative mb-2 md:mb-5 bg-coral",
                            },
                            [
                              !!item.cardImage || !!item.image
                                ? [
                                    item.cardImage[0]
                                      ? _c("img", {
                                          staticClass:
                                            "lazyload absolute top-0 left-0 w-full h-full object-cover",
                                          attrs: {
                                            src: item.cardImage[0].url,
                                            alt: "",
                                          },
                                        })
                                      : _c("img", {
                                          staticClass:
                                            "lazyload absolute top-0 left-0 w-full h-full object-cover",
                                          attrs: {
                                            src: !!item.image[0]
                                              ? item.image[0].url
                                              : "/assets/img/placeholder.svg",
                                            alt: "",
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "absolute top-0 left-0 z-0 w-full h-full block opacity-40 bg-black",
                                    }),
                                  ]
                                : _c("img", {
                                    staticClass:
                                      "lazyload absolute top-0 left-0 z-0 w-full h-full block object-contain p-2",
                                    attrs: {
                                      src: "/assets/img/search-placeholder.svg",
                                      alt: "",
                                    },
                                  }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "z-10" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "font-form-a1 text-xl text-white absolute left-0 top-0 pt-1 pl-4 md:pt-2 md:pl-5 mb-0 tracking-normal",
                              },
                              [
                                item.startDate && item.endDate
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm
                                              .moment(item.startDate)
                                              .format("D.M.") +
                                              " - " +
                                              _vm
                                                .moment(item.endDate)
                                                .format("D.M.YYYY")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : item.startDate
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm
                                              .moment(item.startDate)
                                              .format("D.M.YYYY")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              attrs: {
                                href: item.url,
                                "aria-label": "Read more about " + "item.title",
                              },
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "font-form-a1 text-xl md:text-4xl mb-2 md:mb-4",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.title) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !!item.operator && item.operator[0]
                                ? _c(
                                    "div",
                                    { staticClass: "flex items-center mb-4" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-form-a1 text-base md:text-lg",
                                        },
                                        [_vm._v(_vm._s(item.operator[0].title))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !!item.primaryExperienceType
                                ? _c(
                                    "div",
                                    { staticClass: "flex items-center mb-4" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-form-a1 text-base md:text-lg text-silverChalice",
                                        },
                                        _vm._l(
                                          item.primaryExperienceType,
                                          function (category, categoryKey) {
                                            return _c(
                                              "span",
                                              { key: categoryKey },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(category.title) +
                                                    _vm._s(
                                                      index !=
                                                        Object.keys(
                                                          item.primaryExperienceType
                                                        ).length -
                                                          1
                                                        ? ""
                                                        : ", "
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-between mt-auto py-2 px-1 -mx-1 overflow-x-hidden",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "block button flex items-center self-start justify-center button--white text-black text-1xl font-form-a1",
                            class: {
                              "mr-2":
                                item.ticketLink &&
                                _vm.lastSegment != _vm.vueTranslations.past,
                            },
                            staticStyle: {
                              "white-space": "nowrap",
                              "text-align": "center",
                              border: "1px solid #000",
                            },
                            attrs: { href: item.url },
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v(_vm._s(_vm.vueTranslations.readMore)),
                            ]),
                            _vm._v(_vm._s(_vm.vueTranslations.readMore)),
                          ]
                        ),
                        _vm._v(" "),
                        item.ticketLink &&
                        _vm.lastSegment != _vm.vueTranslations.past
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "button flex items-center self-start justify-center button-black text-white text-1xl font-form-a1 relative",
                                staticStyle: {
                                  "flex-grow": "1",
                                  "white-space": "nowrap",
                                  "text-align": "center",
                                  padding: "11px 16px 15px 16px",
                                },
                                attrs: {
                                  href: item.ticketLink,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.vueTranslations.buyTicketFromProvider
                                    )
                                  ),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.vueTranslations.buyTickets) +
                                    "\n            "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 relative",
                                    staticStyle: { top: "2px" },
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "fill-current",
                                        attrs: {
                                          width: "13px",
                                          height: "18px",
                                          viewBox: "0 0 13 18",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              stroke: "none",
                                              "stroke-width": "1",
                                              fill: "none",
                                              "fill-rule": "evenodd",
                                            },
                                          },
                                          [
                                            _c(
                                              "g",
                                              {
                                                attrs: {
                                                  fill: "#FFFFFF",
                                                  "fill-rule": "nonzero",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M12,0 L12,16 L8.51876872,16 L8.37704804,15.3005854 C8.14016877,14.1315761 7.14720045,13.2913969 6,13.2913969 C4.98495042,13.2913969 4.08063462,13.951606 3.72971592,14.9194361 L3.68711866,15.0473904 L3.6529429,15.1690818 L3.62295247,15.3005829 L3.48123128,16 L0,16 L0,0 L12,0 Z M10.3026667,1.76069565 L1.69666667,1.76069565 L1.69666667,7.85878261 L3.27676764,7.85938945 L3.27676764,9.62072155 L1.69666667,9.62017391 L1.69666667,14.2386087 L2.16466667,14.237913 L2.22785657,14.0830065 C2.78515934,12.7760039 3.94049868,11.8352166 5.30906088,11.5910356 L5.47428719,11.5650988 L5.6476568,11.5457456 L5.82847108,11.5338547 L6,11.5300648 C7.63157632,11.5300648 9.10204521,12.531532 9.76693504,14.0723326 L9.83466667,14.237913 L10.3026667,14.2386087 L10.3026667,9.62017391 L8.58585859,9.62072155 L8.58585859,7.85938945 L10.3026667,7.85878261 L10.3026667,1.76069565 Z M7.39797977,7.85938945 L7.39797977,9.62072155 L4.46464646,9.62072155 L4.46464646,7.85938945 L7.39797977,7.85938945 Z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm.items.length > 0 && _vm.viewMode === "calendar" && !_vm.isLoading
        ? [
            _vm._.size(_vm.eventsByDate) > 0
              ? _c(
                  "section",
                  {
                    key: JSON.stringify(_vm.eventsByDate),
                    staticClass:
                      "mb-4 md:mb-8 lg:mx-5-5 w-full calendar-dates-list",
                  },
                  [
                    _vm._l(_vm.eventsByDate, function (date, key, index) {
                      return [
                        _vm.showDate(date)
                          ? _c("calendar-date", {
                              key: key,
                              attrs: {
                                date: date,
                                first: index === 0,
                                last:
                                  index === _vm._.size(_vm.eventsByDate) - 1,
                              },
                            })
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fadeIn", mode: "in-out" } },
        [
          !_vm.isLoading && _vm.items.length === 0
            ? [
                _c(
                  "h4",
                  {
                    staticClass:
                      "heading-6 mb-6 leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.vueTranslations.notFound) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <a :href="event.url" class="block" :class="{ 'pt-12': !firstItem }">
    <section class="relative flex flex-row max-w-sm md:max-w-none">
      <div class="flex flex-wrap md:justify-between md:flex-no-wrap gap-8 xl:gap-12 w-full">
        <div class="md:w-48 lg:w-2/4">
          <h2 class="font-form-a1 text-2xl md:4xl lg:text-4xxl mb-4">
            {{event.title.length > 63 ? event.title.slice(0, 60) + '...' : event.title}}
          </h2>
          <div v-if="event.primaryExperienceType.length > 0" class="pr-6-5 md:pr-10-5 flex flex-wrap">
            <span 
              v-for="(type, i) of event.primaryExperienceType" 
              :key="i" 
              class="font-form-a1 text-lg text-gray-500 md:small-title"
            >
              {{ i < (event.primaryExperienceType.length - 1) ? type.title + ',&nbsp;' : type.title }}
            </span>
          </div>
        </div>
        <div class="flex flex-col justify-between max-w-140px lg:w-1/4">
          <h3 class="font-form-a1 text-black text-lg md:small-title mb-4" v-if="!!event.operator && event.operator[0]">{{ event.operator[0].title }}</h3>
          <div v-if="showTimes.length > 0" class="pr-6-5 md:pr-10-5">
            <h3 class="font-form-a1 text-silverChalice text-lg md:small-title">{{ vueTranslations.Time }}</h3>
            <div v-for="(showTime, i) of showTimes" :key="i">
              <time v-if="showTime" class="font-form-a1 text-lg md:small-title" :datetime="showTime">
                {{ showTime }}
              </time>
            </div>
          </div>
        </div>
        <div class="lg:text-right lg:w-1/4">
          <div class="inline-flex md:flex-col">
            <a :href="event.url" class="button button button--white--border w-full mr-2-5 md:mr-0 md:mb-2-5">{{ vueTranslations.readMore }}</a>
            <a 
              :href="event.ticketLink" 
              :tabindex="!event.ticketLink ? -1 : 1"
              target="_blank" 
              class="button inline-flex items-center self-start justify-center button-black text-white text-2xl font-form-a1 lg:mt-auto whitespace-no-wrap"
              :class="{'h-0 opacity-0 pointer-events-none py-0': !event.ticketLink}"
            >
              <span class="sr-only">{{ vueTranslations.buyTicketFromProvider }}</span>
              <span class="hidden xs:block">{{ vueTranslations.buyTickets }}</span>
              <span class="xs:ml-2 mt-1">
                <svg width="13px" height="18px" class="fill-current" viewBox="0 0 13 18">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g fill="#FFFFFF" fill-rule="nonzero">
                      <path d="M12,0 L12,16 L8.51876872,16 L8.37704804,15.3005854 C8.14016877,14.1315761 7.14720045,13.2913969 6,13.2913969 C4.98495042,13.2913969 4.08063462,13.951606 3.72971592,14.9194361 L3.68711866,15.0473904 L3.6529429,15.1690818 L3.62295247,15.3005829 L3.48123128,16 L0,16 L0,0 L12,0 Z M10.3026667,1.76069565 L1.69666667,1.76069565 L1.69666667,7.85878261 L3.27676764,7.85938945 L3.27676764,9.62072155 L1.69666667,9.62017391 L1.69666667,14.2386087 L2.16466667,14.237913 L2.22785657,14.0830065 C2.78515934,12.7760039 3.94049868,11.8352166 5.30906088,11.5910356 L5.47428719,11.5650988 L5.6476568,11.5457456 L5.82847108,11.5338547 L6,11.5300648 C7.63157632,11.5300648 9.10204521,12.531532 9.76693504,14.0723326 L9.83466667,14.237913 L10.3026667,14.2386087 L10.3026667,9.62017391 L8.58585859,9.62072155 L8.58585859,7.85938945 L10.3026667,7.85878261 L10.3026667,1.76069565 Z M7.39797977,7.85938945 L7.39797977,9.62072155 L4.46464646,9.62072155 L4.46464646,7.85938945 L7.39797977,7.85938945 Z"></path>
                    </g>
                  </g>
                </svg>  
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </a>
</template>

<script>
import Vue from 'vue';
import moment from 'moment'

export default {
  name: 'Calendar',
  data() {
    return {
      vueTranslations
    }
  },
  props: ['event', 'date', 'firstItem'],
  computed: {
    showTimes: function () {
      let showTimes = !!this.event.showTime ? [moment(this.event.showTime).format('kk:mm')] : [];
      let irregularShowTimes = [];
      if (this.event.irregularShowTimes) {
        irregularShowTimes = this.event.irregularShowTimes.filter(element => {
          return moment(element.date).format('YYYY-MM-DD') === this.date.date;
        })
      }
      if (irregularShowTimes.length > 0) {
        showTimes = irregularShowTimes.map(element => {
          if (element.time) {
            return moment(element.time).format('kk:mm');
          } else {
            return null;
          }
        })
      } else {
        irregularShowTimes = showTimes;
      }
      return showTimes;
    }
  },
};
</script>

// if you're using a bundler, first import:
import Headroom from "headroom.js";
import { forEach } from "lodash";
// grab an element
var anchorNav = document.querySelector(".experience-navigation");
var mainNav = document.querySelector(".site-header--headroom");


var optionsMain = {
  offset: mainNav.offsetHeight,
  tolerance: {
    up: 5,
    down: 0
  }
};

// construct an instance of Headroom, passing the element
var headroomMain = new Headroom(mainNav, optionsMain);

// initialise
headroomMain.init();

if (anchorNav) {
  var heroHeight = document.querySelector('.hero').offsetHeight;
  var anchorNavHeight = anchorNav.offsetHeight / 2;
  var optionsAnchor = {
    offset: heroHeight + anchorNavHeight,
    tolerance: {
      up: 5,
      down: 0
    }
  };
  var headroomAnchor = new Headroom(anchorNav, optionsAnchor);
  headroomAnchor.init();

  anchorNav.querySelectorAll('a').forEach((link) => {

    link.addEventListener("click", (e) => {
      e.preventDefault();
      const targetSelector = link.getAttribute('href');
      const target = document.querySelector(targetSelector);

      let targetOffset = target.getBoundingClientRect().top + window.scrollY - (anchorNav.offsetHeight);
      if (target.getBoundingClientRect().top < anchorNav.offsetHeight) {
        targetOffset = target.getBoundingClientRect().top + window.scrollY - (mainNav.offsetHeight + anchorNav.offsetHeight);
      }

      window.scrollTo(0, targetOffset);

      return false;
    })

  });
}

<template>
  <div class="flex gap-4-5 px-2">
    <button 
      class="flex items-center text-xl lg:text-2xl button uppercase"
      :class="{ 'button--white--border': viewMode === 'calendar'}"
      @click="updateViewMode('list')"
    >
      <span class="mr-4">
        {{ vueTranslations.list }}
      </span>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor">
        <line x1="3" y1="7" x2="15" y2="7" stroke="currentColor" stroke-width="2"/>
        <line x1="3" y1="12" x2="15" y2="12" stroke="currentColor" stroke-width="2"/>
        <line x1="3" y1="17" x2="15" y2="17" stroke="currentColor" stroke-width="2"/>
        <line x1="17" y1="7" x2="22" y2="7" stroke="currentColor" stroke-width="2"/>
        <line x1="17" y1="12" x2="22" y2="12" stroke="currentColor" stroke-width="2"/>
        <line x1="17" y1="17" x2="22" y2="17" stroke="currentColor" stroke-width="2"/>
      </svg>
    </button>
    <button 
      class="flex items-center text-xl lg:text-2xl button uppercase"
      :class="{ 'button--white--border': viewMode === 'list'}"
      @click="updateViewMode('calendar')"
    >
      <span class="mr-4">
        {{ vueTranslations.calendar }}
      </span>
      <svg width="22" height="21" viewBox="0 0 22 21" fill="currentColor">
        <rect x="4" y="6" width="17" height="14" stroke="currentColor" fill="transparent" stroke-width="2"/>
        <line x1="8" y1="2" x2="8" y2="7" stroke="currentColor" stroke-width="2"/>
        <line x1="17" y1="2" x2="17" y2="7" stroke="currentColor" stroke-width="2"/>
        <line x1="12" y1="11" x2="7" y2="11" stroke="currentColor" stroke-width="2"/>
        <line x1="12" y1="15" x2="7" y2="15" stroke="currentColor" stroke-width="2"/>
      </svg>
    </button>
    <div class='hidden md:flex text-grey-dark lg:items-center flex-grow justify-start max-w-2xs uppercase font-form-a1 text-2xl leading-tight'>
      <div class='self-center flex w-full'>
        <span
          class='self-center cursor-pointer'
          @click="performSearch()"
        >
          <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g transform="translate(-3.000000, -4.000000)" fill="#353535" fill-rule="nonzero">
              <g transform="translate(14.500000, 14.727922) rotate(45.000000) translate(-14.500000, -14.727922) translate(2.500000, 6.727922)">
                  <path d="M15.9792397,7.82123552 L23.2518001,7.82123552 C23.6383994,7.82123552 23.9518001,8.1346362 23.9518001,8.52123552 C23.9518001,8.90783485 23.6383994,9.22123552 23.2518001,9.22123552 L15.9121856,9.22123552 C15.3867022,13.0424587 12.1084968,15.9857143 8.14285714,15.9857143 C3.81136675,15.9857143 0.3,12.4743475 0.3,8.14285714 C0.3,3.81136675 3.81136675,0.3 8.14285714,0.3 C12.3666192,0.3 15.8105449,3.63887666 15.9792397,7.82123552 Z M8.14285714,14.5857143 C11.7011489,14.5857143 14.5857143,11.7011489 14.5857143,8.14285714 C14.5857143,4.5845654 11.7011489,1.7 8.14285714,1.7 C4.5845654,1.7 1.7,4.5845654 1.7,8.14285714 C1.7,11.7011489 4.5845654,14.5857143 8.14285714,14.5857143 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </svg>
        </span>
        <input
          v-bind:value="query"
          v-on:input="$emit('update:query', $event.target.value)"
          class="ml-3 self-center hover:text-grey transition-normal-text w-full"
          type="text"
          :placeholder="vueTranslations.search"
          aria-label="Search"
        >
      </div>
    </div>

    <a :href="pastUrl" class="button button--white hover:bg-black hover:text-white hidden md:flex items-center lg:text-2xl uppercase font-form-a1 text-2xl leading-non past-events-link" :title="vueTranslations.pastEvents">{{ vueTranslations.pastEvents }}</a>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'ViewSelector',
  props: {
    viewMode: {
      type: String,
      default: 'list'
    },
    query: {
      type: String,
      default: '',
    },
  },
  created: function() {
    const self = this
    const catBlocks = _.flatMapDeep(this.categoryFilters)
    _.each(catBlocks, catBlock => {
      if (!!catBlock && this.selectedCategories.includes(catBlock.id)) {
        this.selectedCategories.push(catBlock)
      } else {
        const cat = _.find(catBlock.categories, c => {
          return this.selectedCategories.includes(c.id)
        })
        if (!!cat) {
          let index = _.findIndex(this.selectedCategories, o => o === cat.id)
          this.selectedCategories[index] = {...cat, parentCategory: catBlock};
        }
      }
    })
  },
  mounted: function() {
  },
  updated: function() {},
  destroyed: function() {
  },
  data() {
    return {
      isMobileFilter: false,
      vueTranslations,
      pastUrl
    }
  },
  components: {
  },
  computed: {
    _() {
      return _;
    },
    moment() {
      return moment;
    },
    singleCategoryFilters() {
      const singleCategoryFilters = _.filter(this.categoryFilters, { 'fieldType': 'single'});
      return singleCategoryFilters;
    },
    multipleCategoryFilters() {
      const multipleCategoryFilters = _.filter(this.categoryFilters, { 'fieldType': 'multiple'});
      return multipleCategoryFilters;
    }
  },
  methods: {
    updateViewMode: function(mode) {
      this.$emit('update:view-mode', mode);
    },
    performSearch: function() {
      this.$emit('search');
    }
  }
}
</script>
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

const preventScroll = (event) => {
  if(event.keyCode === 32) {
    event.preventDefault();
    window.removeEventListener('keydown', preventScroll);
  }
}

let collageGallery = function() {
  let galleryHtml =
    '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true"><div class="pswp__bg"></div><div class="pswp__scroll-wrap"><div class="pswp__container"><div class="pswp__item"></div><div class="pswp__item"></div><div class="pswp__item"></div></div><div class="pswp__ui pswp__ui--hidden"><div class="pswp__top-bar"><div class="pswp__counter"></div><button class="pswp__button pswp__button--close" title="Close (Esc)"></button><div class="pswp__preloader"><div class="pswp__preloader__icn"><div class="pswp__preloader__cut"><div class="pswp__preloader__donut"></div></div></div></div></div><div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"><div class="pswp__share-tooltip"></div></div><button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button><button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button><div class="pswp__caption"><div class="pswp__caption__center"></div></div></div></div></div>';
  let galleryImage = document.querySelectorAll('.gallery__image');
  let galleryTrigger = document.querySelector('.show-gallery');
  let openGallery = function(glry, slideIndex) {
    let galleryElem = document.querySelectorAll('.pswp')[0];
    let items = JSON.parse(glry.getAttribute('data-slides'));
    let options = {
      index: slideIndex,
      getThumbBoundsFn: function(index) {
        let collage = galleryImage;
        let pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        let rect = collage[0].getBoundingClientRect();
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      },
      showHideOpacity: true,
      showAnimationDuration: 333,
      hideAnimationDuration: 333,
      bgOpacity: 0.9,
      spacing: 0.12,
      allowPanToNext: true,
      maxSpreadZoom: 2,
      loop: true,
      pinchToClose: true,
      closeOnScroll: false,
      closeOnVerticalDrag: true,
      mouseUsed: false,
      escKey: true,
      arrowKeys: true,
      history: false,
      errorMsg:
        '<div class="pswp__error-msg"><a href="%url%" target="_blank">The image</a> could not be loaded.</div>',
      focus: true,
      modal: true,
      captionEl: true,
      addCaptionHTMLFn: function(item, captionEl, isFake) {
        // item      - slide object
        // captionEl - caption DOM element
        // isFake    - true when content is added to fake caption container
        //             (used to get size of next or previous caption)

        if(!item.title) {
            captionEl.children[0].innerHTML = '';
            return false;
        }
        captionEl.children[0].innerHTML = item.title;
        return true;
      },
    };
    let gallery = new PhotoSwipe(
      galleryElem,
      PhotoSwipeUI_Default,
      items,
      options
    );

    gallery.init();

    gallery.listen('gettingData', function(index, item) {
      if (item.w < 1 || item.h < 1) {
        let img = new Image();
        img.onload = function() {
          item.w = this.width;
          item.h = this.height;
          gallery.updateSize(true);
        };
        img.src = item.src;
      }
    });

    const closeOnEscape = (event) => {
      if(event.keyCode === 27) {
        setTimeout(()=>{
          glry.querySelectorAll('.gallery__image')[slideIndex].focus();
        }, 400);
  
        document.removeEventListener('keydown', closeOnEscape);
      }
    }

    document.addEventListener('keydown', closeOnEscape);
  };

  if (galleryTrigger) {
    document.body.insertAdjacentHTML('beforeend', galleryHtml );

    galleryImage.forEach(function(img) {
      img.addEventListener('click', function(e) {
        e.preventDefault();

        let chosenGallery = this.closest('.gallery__images');
        // let $slides = chosenGallery.find('.gallery__image');
        let slideIndex = parseInt(this.getAttribute('data-index'));

        openGallery(chosenGallery, slideIndex);

        return false;
      });
      img.addEventListener('keydown', function(event) {
        if(event.keyCode === 32 || event.keyCode === 13) {
          event.preventDefault();
          if(event.keyCode === 32) {
            window.addEventListener('keydown', preventScroll, false);
          }
          let chosenGallery = this.closest('.gallery__images');
          let slideIndex = parseInt(this.getAttribute('data-index'));
  
          openGallery(chosenGallery, slideIndex);
  
          return false
        }
      });
    });

    galleryTrigger.addEventListener('click', function(e) {
      e.preventDefault();

      let chosenGallery = document.querySelector('.gallery__images');
      let slideIndex = 0;

      openGallery(chosenGallery, slideIndex);

      return false;
    });

  }
};

collageGallery();
<template>
  <div
    v-if="vCalendarIsDefined"
    class="relative xl:mr-3 flex"
    :class="{'mt-4': isMobile, 'mt-3': !isMobile}"
  >
    <form @submit.prevent class="w-full xl:w-auto">
      <h4 class="heading-6 mb-6 text-black leading-slight text-xl font-normal text-grey-dark max-w-2xs mx-auto">{{ vueTranslations.chooseDate }}</h4>
      <div class="flex h-auto relative bg-blue-dark">
        <v-date-picker 
          :attributes="attrs" 
          v-model="date" 
          is-range 
          :locale="siteLang" 
          color="gray"
          :min-date="new Date()"
        />
        <div class="relative">
        <button
          type="button"
          class="text-black font-form-a1 uppercase absolute z-40"
          style="bottom: 0; right: 0;"
          :class="{'h-14-5 px-6': isMobile, 'h-9-5 px-4': !isMobile}"
          @click="clearDates"
          v-if="date !== null"
        >
          {{ vueTranslations.clear }}
        </button>
      </div>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import VCalendar from 'v-calendar';
import _ from 'lodash';

export default {
  props: ['startDate', 'endDate', 'isMobile', 'siteLang', 'eventsByDate', 'allDates'],
  data() {
    return {
      date: null,
      theme: {
        container: {
          light: 'bg-blue-dark text-white border border-solid border-white',
        },
        title: {
          light: 'text-white',
        },
        weekdays: {
          light: 'text-white',
        },
        bgAccentLow: {
          light: 'bg-orange-light',
        },
        bgAccentHigh: {
          light: 'bg-orange',
        },
        bgLow: {
          light: 'bg-grey-lighter',
        },
      },
      vCalendarIsDefined: false,
      vueTranslations,
      attrs: []
    }
  },
  updated() {
    if (!this.date) {
      // this.$emit('update:start-date', null)
      // this.$emit('update:end-date', null)
    } else {
      if (this.date.start) {
        const formattedStartDate = moment(this.date.start).format('YYYY-MM-DD');
        this.$emit('update:start-date', formattedStartDate)
      }
      if (this.date.end) {
        const formattedEndDate = moment(this.date.end).format('YYYY-MM-DD');
        this.$emit('update:end-date', formattedEndDate)
      }
    }
  },
  created() {
    if (!_.isEmpty(this.allDates)) {
      let eventDateHighlights = [];
      if (this.allDates) {
        eventDateHighlights = {
          bar: true,
          dates: this.allDates,
        }
        this.attrs = [eventDateHighlights];
      }
    }
    if(!!VCalendar) {
      Vue.use(VCalendar, {
        firstDayOfWeek: 2, // Monday
        masks: {
          input: ['D MMM YYYY'],
          weekdays: this.siteLang === 'en' ? 'WWW' : 'WW' 
        },
      })
    }
    if (!_.isEmpty(this.startDate)) {
      this.date = {
        ...this.date,
        start: new Date(this.startDate),
      }
    }
    if (!_.isEmpty(this.endDate)) {
      this.date = {
        ...this.date,
        end: new Date(this.endDate),
      }
    }
    this.vCalendarIsDefined = !!VCalendar;
  },
  watch: {
    startDate: function(newVal, oldVal) {
      if (_.isEmpty(newVal)) {
        this.date = null
      }
    },
    allDates: function(newVal, oldVal) {
      if (!_.isEmpty(newVal)) {
        let eventDateHighlights = [];
        if (this.allDates) {
          eventDateHighlights = {
            bar: true,
            dates: this.allDates,
          }
          this.attrs = [eventDateHighlights];
        }
      }
    }
  },
  methods: {
    clearDates: function() {
      const formattedStartDate = moment().format('YYYY-MM-DD');

      this.$emit('update:start-date', formattedStartDate)
      this.$emit('update:end-date', null)
      this.date = null;
    }
  }
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VueSlickCarousel",
    _vm._b(
      { on: { afterChange: _vm.afterCurrentChange } },
      "VueSlickCarousel",
      _vm.settings,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }